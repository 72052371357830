<template>
  <main class="mainlayout presentation">
 
    <Results 
      v-bind:conditions="conditions" 
      v-bind:questions="questions" 
      v-bind:results="results" 
      v-bind:contentRoot="contentRoot" 
    />

    <Hotspots v-if="hotspots && hotspots.length" style="padding: 20px;"
      :hotspots="hotspots"
      :results="results"
      :segmentId="segmentId"
      :labelName="hotspotsLabelName"
    />  
 
  </main>
</template>

<script>
import Results from '@/components/Results.vue'
import Hotspots from '@/components/Hotspots.vue'

export default {
  name: 'Presentation',
  data: function(){
    return {     
      conditions: undefined,
      questions: undefined,
      results: undefined,  
      hotspots: undefined,  
      hotspotsLabelName: undefined,  
      segmentId: undefined,  
    }
  },
  computed: {  
  },
  methods: { 
    parse(){
      let presentation = JSON.parse(localStorage.getItem("presentation"))
      this.conditions = presentation?.conditions
      this.questions = presentation?.questions
      this.results = presentation?.results
      this.hotspots = presentation?.hotspots
      this.segmentId = presentation?.segmentId
      this.hotspotsLabelName = presentation?.hotspotsLabelName
      this.contentRoot = presentation?.contentRoot
    }     
  },  
  components: {
    Results,
    Hotspots
  },
  created() {
    this.parse()

    window.addEventListener('storage', (e) => {
        if (e.key == 'presentation')
          this.parse() 
        else if (e.key == 'auth-token' && !e.newValue){          
          window.close()
        }
    })
  }
}
</script>

<style lang="scss">

main.presentation{
  display: block;
}

</style>
