<template>
  <div class="container results">
    <div class="conditions" v-if="conditions">
      <div v-for="condition in conditions" :key="condition.condition"
        class="condition"
        :class="{active: segmentResultsCondition == condition, hide: (!segmentResultsCondition && condition.condition == 'summary') || condition.condition == 'summary'}"
        @click="segmentResultsCondition = condition"
      >
        <span v-if="condition.condition != 'summary'" class="letter">{{condition.txt1}}</span> <!--<span class="name">{{condition.condition != 'summary' ? condition.txt1 : 'All conditions'}}</span> -->
        <span class="name" v-if="condition.condition == 'summary'">All conditions</span> 
      </div>      
    </div>
    <!-- <div class="showdetails">
      <input type="checkbox" v-model="showDetails" id="showdetails" /><span>Show details</span>
    </div> -->
    <div v-for="question in filteredQuestions" :key="question.id" class="question" :class="{ranking: question.ranking}">
      <div class="title" @click="toggle">{{stripHtml(question.Qdashboard ? question.Qdashboard : question.Q)}}</div>          
      <div class="answers">
        <div class="totalcount" v-if="!question.ranking">{{answerCount(question.id)}} Answers
          <span class="correctcount" v-if="question.type == 'rating'"> Rating average: {{ratingAverage(question)}}</span>
          <span class="correctcount" v-if="showDetails && (question.Answer || (question.Answers && question.Answers.length))">{{resCorrect(question)}} users got all answers correct</span>
        </div>
        <!-- TODO this might make sense in a separate component when it will be used on the separate results page or such -->
        <table v-if="question.ranking">
          <tr>
            <th>
              {{answerCount(question.id)}} Answers              
            </th>
            <th>Score</th>
            <th>Distribution</th>
            <th class="palette">
              Most 
              <span v-for="index in 6" :key="index" :style="{backgroundColor: getRankColor(index-1, 5)}"></span>
              Least
            </th>
          </tr>
          <tr v-for="rankedOption in resRank(question)" :key="rankedOption.option.id" class="option">
            <!-- <td>This is a ranking question. option: {{rankedOption.option.id}}, score: {{rankedOption.score}}, distribution:
              <span v-for="(val, index) in rankedOption.distribution" :key="index">{{val}}%, </span>
            </td> -->
            <td class="label">
              <!-- <span class="answercount" :class="{zero: rankedOption.score == 0}">
                Score: {{rankedOption.score}}
              </span> -->
              {{stripHtml(rankedOption.option.textDashboard ? rankedOption.option.textDashboard : rankedOption.option.text)}}
              <div v-if="rankedOption.option.image">
                <br>
                <img  :src="contentRoot + rankedOption.option.image" width="100" />
              </div>
            </td>
            <td class="score">              
              {{rankedOption.score}}              
            </td>
            <td class="chart" colspan="2">
              <div class="bar">
                <div v-for="(val, index) in rankedOption.distribution" :key="index" class="progress distribution" v-bind:style="{ flexGrow: val, backgroundColor: getRankColor(index, rankedOption.distribution.length-1, val) }">
                  <div class="value">{{val}}%</div>
                </div>
              </div>              
            </td>
          </tr>
        </table>
        <table v-else>
          <tr v-for="option in question.options" :key="option.id" class="option">
            <td class="label" :class="{correct: showDetails && (question.Answer == option.id || (question.Answers && question.Answers.includes(option.id)))}">
              <span class="answercount" :class="{zero: res(question.id, option.id).length == 0}">
              {{res(question.id, option.id).length}}
              </span>
              {{stripHtml(option.textDashboard ? option.textDashboard : option.text)}}
              <div v-if="option.image">
                <br>
                <img  :src="contentRoot + option.image" width="100" />
              </div>
            </td>
            <td class="chart">
              <div class="bar">
                <div class="progress" v-bind:style="{ width: resPercentage(question.id, option.id)+'%' }"></div>
                <div class="value">{{resPercentage(question.id, option.id)}}%</div>
              </div>
              <div v-if="showDetails && question.showValueAverage" class="average">
                {{question.valueAverageLabel ? question.valueAverageLabel : 'data average' }}: <span>{{resAverage(question.id, option.id)}}</span>
              </div>
              <div v-if="showDetails && question.showValueSum" class="sum">
                {{question.valueSumLabel ? question.valueSumLabel : 'data sum' }}: <span>{{resSum(question.id, option.id)}}</span>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>



<script>

//import groupBy from 'lodash/groupBy';
//import toArray from 'lodash/toArray';
//import chain from 'lodash/chain';
//import xor from 'lodash/xor';
import _ from 'lodash';
import { saveAs } from 'file-saver'
import chroma from 'chroma-js'


export default {
  name: 'Results',
  props: {
    conditions: Array,
    questions: Array,
    results: Array,
    contentRoot: String,
    selectedCondition: Object
  },
  data: function() {
    return {            
      segmentResultsCondition: undefined,
      showDetails: true,
      temp: undefined
    };
  },
  computed: {
    filteredQuestions(){
      return this.questions.filter(q => !this.segmentResultsCondition || this.segmentResultsCondition.condition == q.condition)
    }
  },
  methods: {
    getRankColor(rank, total, val){
      if(val == 0)
        return "#bfc5d1";
      const f = chroma.scale([ '#199dcf', '#28beb9', '#19b271', '#83bc38', '#c9ce44', '#e2a24e', '#d97550', '#ce4e4e']);
      //console.log(f(0.25).toString())
      return f(rank/total).toString()
      //return `rgba(31, 113, 188, ${(rank/20)*0.77})`
    },
    toggle(ev){      
      ev.target.classList.toggle('collapsed');
    },
    async export(fileName){                   
      let csv = "QuestionID,Question,Condition,OptionID,Option,Date,UserID,ResultID,Value,Rank"    
      let relevantSegmentIds = this.questions.map(q => q.segmentId)      
      let relevantResults = this.results.filter(r => relevantSegmentIds.includes(r.segmentId))
      let metadataKeys = Array.from(new Set(relevantResults.flatMap(r => Object.keys(r.metadata)))).filter(m => !m.startsWith('cp_'))
      metadataKeys.forEach(key => {
        csv += `,metadata_${key}`
      });

      relevantResults.forEach(r => {
        let question = this.questions.find(q => q.id == r.questionId)        
        csv += `\n${r.questionId},"${this.stripHtml(question?.Q?.replace(/(")/g, "\"\""))}",${question?.condition ?? ''},${r.optionId},"${this.stripHtml(question?.options?.find(o => o.id == r.optionId)?.text?.replace(/(")/g, "\"\""))}",${r.date},${r.userId},${r.id},${r.value},${r.rank}`
        metadataKeys.forEach(key => {
          csv += `,${r.metadata[key] ?? ''}`
        });
      })
      //console.log(csv)
      let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
      saveAs(blob, `${fileName ?? 'unknown-session-segment-results-export'}.csv`);
    },
    async export2_1(fileName, externalUserId){          
      let relevantSegmentIds = this.questions.map(q => q.segmentId)      
      let relevantResults = this.results.filter(r => relevantSegmentIds.includes(r.segmentId))
      let metadataKeys = Array.from(new Set(relevantResults.flatMap(r => Object.keys(r.metadata)))).filter(m => !m.startsWith('cp_'))
      //let externalUserId = undefined
      if(metadataKeys?.length){
        // TODO select which one is the external_userId if any (maybe a modal popup will be better), then call the same as below
        // externalUserId = 'pnumber'
        if(!externalUserId && metadataKeys.includes('pnumber'))
          await this.export2_2(fileName, relevantResults, metadataKeys, 'pnumber')
        else
          await this.export2_2(fileName, relevantResults, metadataKeys, externalUserId)
      }
      else
        await this.export2_2(fileName, relevantResults, metadataKeys, null)
    },
    async export2_2(fileName, relevantResults, metadataKeys, externalUserId){   

      let uniqueQuestions = [...new Map(this.questions.map(item =>[item['id'], item])).values()]

      let csv = ''  
      // headers
      if(externalUserId)
        csv += 'ExternalUserID,'
      metadataKeys.forEach(key => {
        csv += `metadata_${key},`
      })
      csv += "Condition,ConditionName,Date,InternalUserID"
      uniqueQuestions.forEach(question => {        
        question.options.forEach(option => {
          csv += `,Q${question.ranking ? 'ranking' : ''}_${question.id}_${option.id}`
        })    
      })

      // question text 'fake' row in csv for human friendlyness
      csv += '\n'
      if(externalUserId)
        csv += ','
      metadataKeys.forEach(() => {
        csv += `,`
      })
      csv += ",,,"
      uniqueQuestions.forEach(question => {        
        question.options.forEach((option,index) => {
          csv += `,"${index == 0 ? this.stripHtml(question?.Q?.replace(/(")/g, "\"\"")) : ''}"`
        })    
      })
      csv += '\n'
      if(externalUserId)
        csv += ','
      metadataKeys.forEach(() => {
        csv += `,`
      })
      csv += ",,,"
      uniqueQuestions.forEach(question => {               
        question.options.forEach(option => {
          csv += `,"${this.stripHtml(option?.text?.replace(/(")/g, "\"\""))}"`
        })    
      })

      // results rows
      let questionsGroupedByConditions = _(this.questions).groupBy('condition').map((value, key) => ({condition: key, questions: value})).value()
      let userResults = _(relevantResults).groupBy(externalUserId ? `metadata.${externalUserId}` : 'userId').map((value, key) => ({userId: key, answers: value})).value()
      
      userResults.forEach(u => {
        // one row for each condition needed per user, in case user has results for multiple conditions...
        let userResultsPerCondition = []
        questionsGroupedByConditions.forEach(g => {
          let tempQuestionIds = g.questions.map(q => q.id)
          let tempSegmentIds = g.questions.map(q => q.segmentId)  // in case of same question with same Id is present in different conditions, then we must further isolate data per row (otherwise data for the question from all conditions might get put in here)
          userResultsPerCondition.push( ({condition: g.condition, answers: u.answers.filter(a => tempQuestionIds.includes(a.questionId) && tempSegmentIds.includes(a.segmentId))}) )
        })

        userResultsPerCondition.forEach(ur => {
          if(ur.answers?.length){
            csv += '\n'
            if(externalUserId)
              csv += `${u.userId},` 

            metadataKeys.forEach(key => {
              // csv += `${ur.answers[0]?.metadata[key]},`  // this only got "first or default" metadata value
              let metadataValues = [...new Set(ur.answers?.filter(u => u.metadata[key]).map(u => u.metadata[key]))]  // in case multiple different metadata for same user              
              csv += `${metadataValues.join(';')},`
            })
                      
            // the date cannot be flattened, we can just only pick the first...
            csv += `${ur.condition == 'undefined' ? '' : ur.condition},${this.conditions?.find(co => co.condition == ur.condition)?.txt1 ?? ''},${ur.answers?.length ? ur.answers[0].date : ''},${externalUserId ? (ur.answers[0] ? ('probably ' + ur.answers[0].userId) : '') : u.userId}`
            
            uniqueQuestions.forEach(question => {              
              question.options.forEach(option => {
                let optionAnswers = ur.answers.filter(a => a.questionId == question.id && a.optionId == option.id)  // here the data is already only for a specific condition, so if question was reused across multiple conditions that will work here correctly
                if(!optionAnswers?.length)
                  csv += `,0`
                else if(optionAnswers.length == 1){
                  csv += `,${optionAnswers[0].value ?? 1}`
                  if(question.ranking)
                    csv += `_rank${optionAnswers[0].rank}`
                }
                // in case of multiple answers for same question/option, use the correlationId to distinguish between them
                else if(optionAnswers.length > 1){
                  let answersGroupedByCorrelationId = _(optionAnswers).groupBy('correlationId').map((value, key) => ({correlationId: key, answers: value})).value()
                  csv += ','
                  answersGroupedByCorrelationId.forEach(element => {
                    csv += `(correlationId:${element.correlationId}`
                    element.answers.forEach(a => {
                      csv += `/${a.value ?? 1}`
                      if(question.ranking)
                        csv += `_rank${a.rank}`
                    })                  
                    csv += ')'
                  })
                }
              })    
            })
          }
        })        

      })

      let blob = new Blob([csv], {type: "text/plain;charset=utf-8"});
      saveAs(blob, `${fileName ?? 'unknown-session-segment-results-export'}.csv`);
    },
    stripHtml(html){
      return html?.replace(/(<([^>]+)>)/gi, "")
    }, 
    answerCount(questionId){      
      return new Set(this.res(questionId).map(r => r.correlationId ?? r.date))?.size ?? 0 // date just for backward compatibility
    },
    res(questionId, optionId){
      return this.results.filter(r => 
        r.questionId == questionId 
        && (!optionId || r.optionId == optionId)
        && (!this.segmentResultsCondition || this.segmentResultsCondition.condition == 'summary' || r.segmentId == this.segmentResultsCondition.id))     
    },
    resPercentage(questionId, optionId){      
      let p = this.res(questionId, optionId).length/this.res(questionId).length*100
      return p ? (Math.round(p * 100) / 100)  : 0     
    },
    resAverage(questionId, optionId){      
      let p = _.mean(this.res(questionId, optionId).map(r => r.value))
      return p ? (Math.round(p * 100) / 100)  : 0  
    },
    resSum(questionId, optionId){      
      let p = _.sum(this.res(questionId, optionId).map(r => r.value))
      return p ? (Math.round(p * 100) / 100)  : 0  
    },
    ratingAverage(question){
      let sum = 0
      let count = 0
      question.options.forEach(option => {
        let localCount = this.res(question.id, option.id).length
        let localSum = localCount * option.id // rating is done with having options from 1 to 5
        sum += isNaN(localSum) ? 0 : localSum
        count += isNaN(localCount) ? 0 : localCount
      })
      let ret = sum / count
      return isNaN(ret) ? 0 : ret
    },
    resCorrect(question){      
      let res = this.res(question.id)
      let correctAnswers = question.Answers ?? []
      if(question.Answer && !correctAnswers.includes(question.Answer))
        correctAnswers.push(question.Answer)
      //let userResults = toArray(groupBy(res, 'userId'))
      let userResults = _(res).groupBy('userId').map((value, key) => ({userId: key, answers: value.map(v => v.optionId)})).value()
      let count = _.countBy(userResults, (u) => _.xor(u.answers, correctAnswers).length == 0)      
      // if(!this.temp)
      //   this.temp = res
      //console.log(count)
      return count.true ?? 0
    },
    resRank(question){
      let rankedResults = question.options.map(o => this.optionRank(question, o))
      return _.orderBy(rankedResults, ['score'], ['desc'])
    },
    optionRank(question, option){
      let score = 0
      let distribution = []
      let res = this.res(question.id, option.id)    
      //debugger
      // weighted average score, #1 choice has highest weight, #n has lowest weight (in reverse)
      for (let r = 1; r <= question.options.length; r++) {
        const w = question.options.length + 1 - r
        const c = res.filter(u => u.rank == r).length
        const d = Math.round(c/res.length*100*10)/10
        distribution.push(isNaN(d) ? 0 : d)
        score += c*w;
      }

      const s = Math.round(score / res.length * 100) / 100
      return {option: option, score:  isNaN(s) ? '-' : s, distribution: distribution.filter(f => f > 0).length ? distribution : null}
    },
    setAnimationMaxHeight(){
      document.querySelectorAll('.question .answers').forEach(el => {
        el.style.maxHeight = el.clientHeight + 'px';
      })
    }
  },
  created() {
    this.segmentResultsCondition = this.conditions ? this.conditions[1] : undefined
  },
  mounted(){
    setTimeout(this.setAnimationMaxHeight, 1000)
  },  
  watch: {
    conditions: function (/*newItem, oldItem*/) {
      this.segmentResultsCondition = this.conditions ? (this.conditions.find(c => c.id == this.segmentResultsCondition.id) ?? this.conditions[1]) : undefined      
    },
    questions: function(){
      setTimeout(this.setAnimationMaxHeight, 1000)
    },
    segmentResultsCondition: function(/*newItem /*,oldItem*/){
      this.$emit('conditionSelected', this.segmentResultsCondition)
    },
    selectedCondition: function(/*newItem /*,oldItem*/){
      this.segmentResultsCondition = this.selectedCondition
      //console.log('selectedCondition' + newItem.condition)
    }
  },
}
</script>



<style lang="scss">

.results{
  margin: 15px auto 15px auto;
  border: $bordermixin;
  border-radius: $borderradius;
  position: relative;
  clear: both;

  .conditions{
    padding: 20px 20px 0 20px;
    overflow: hidden;
    border-bottom: $bordermixin;
    background-color: white;

    .condition{
      margin-bottom: 0;
      padding-bottom: 14px;
    }    
  }

  .showdetails{
    color: $july;
    position: absolute;
    right: 10px;
    top: 18px;

    span{
      vertical-align: middle;
      font-size: 14px;
      padding-left: 4px;
    }
    input{
      vertical-align: middle;
      cursor: pointer;
    }
  }

  .question{
        
    .title{
      border-bottom: $bordermixin;
      padding: 20px;
      background-color: #F8F9FB;
      color: $december;
      font-size: 16px;
      font-weight: bold;
      padding-right: 120px;
      cursor: pointer;
      position: relative;

      &:after{
        content: '\276E';
        position: absolute;
        right: 20px;
        top: 40%;
        color: #a5a5a5;
        opacity: 0.5;
        transform: rotate(90deg);
        transition: transform 0.5s ease;      
      }

      &:hover:after{
        opacity: 1;
      }

      &.collapsed{
        & + .answers{
          max-height: 0px !important;
          padding-bottom: 0px;
          padding-top: 0px;
          overflow: hidden;               
          transform-origin: top;  
          transform: scaleY(0); 
        }

        &:after{
          transform: rotate(270deg);
        }
      }
    }

    &.ranking{
      .score{
        font-size: 15px;
        font-weight: bold;
      }
      th{
        color: #686E71;
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
        text-align: left;
      }
      .bar{
        display: flex;
      }
      .palette{ 
        text-align: center;
        span{
          display: inline-block;
          margin: 0 5px;
          width: 16px;
          height: 16px;
          border-radius: 2px;
          vertical-align: top;
        }
      }
    }

    .answers{
      padding: 40px 60px;
      transition: all 0.5s ease;
      transform: scaleY(1); 

      table{
        width: 100%;

        td{
          padding: 15px 0;
        }
      }

      .totalcount{
        color: #686E71;
        font-weight: bold;
        font-size: 15px;
        margin-bottom: 10px;
      }

      .correctcount{
        font-size: 13px;
        float: right;
      }

      .option{

        .label{
          font-weight: bold;
          font-size: 15px;
          padding-right: 25px;

          &.correct{
            color: green;
          }

          img{
            width: 100px;
            border-radius: 3px;
          }

          .answercount{
            margin-right: 7px;
            background-color: #DFE6EA;
            font-weight: bold;
            font-size: 12px;
            line-height: 24px;
            padding: 0 5px;
            height: 24px;
            display: inline-block;
            border-radius: $borderradius;

            &.zero{
              opacity: 0.5;
            }
          }
        }

        .chart{
          //background-color: red;
          min-width: 400px;

          .bar{
            width: 100%;
            border-radius: $borderradius;
            background-color: $january;
            height: 24px;
            position: relative;

            

            .progress{
              background-color:  rgba(31, 113, 188, 0.77);
              width: 33%;
              height: 24px;
              border-radius: $borderradius;
              position: absolute;

              &.distribution{
                .value{
                  color: white;
                }
                min-width: 70px;
                width: auto;
                overflow: hidden;
                float: left;
                position: relative;
                //border-left: 2px dotted white;
                
              }
            }

            .value{
              position: absolute;
              left: 0;
              right: 0;
              text-align: center;
              height: 24px;
              line-height: 24px;
              color: #686E71;
              font-size: 12px;
              font-weight: bold;
            }
          }

          .average, .sum{
            color: $july;
            margin-top: 5px;
            font-size: 15px;

            span{
              color: black;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

</style>
